import React, {useState} from "react";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {Main_URL} from "../../services/config.service";
import {GetDeptSSODetails, SSOLoginRequest} from "../../services/sso.service";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import OIDCNewUser from "./OIDC_NewUser";
import {AcceptedSystemNotice} from "../../services/auth.service";
import {Dialog} from "@mui/material";
import SystemNotice from "../../components/authentication/login/_systemNotice";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SAML_LOGIN() {
    const { DeptCode } = useParams<any>();
    const [loginState, setLoginState] = React.useState<string>("INIT");
    const [systemNotice, setSystemNotice] = React.useState(false);
    
    const [acct, setAcct] = React.useState<UserAttributes>({});
    const [token, setToken] = React.useState<string>("");
    const [initialRun, setInitialRun] = React.useState<boolean>(false);

    let q = useQuery();
    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true);
            let code = q.has('guid') ? q.get('guid') : null;
            SSOLoginRequest({
                Type: 'SAML',
                DeptCode: null,
                MainURL: Main_URL,
                Code: code
            }).then((res) => {
                if(res && res.resp_type === "NEW_PROFILE"){
                    setAcct(res.acct);
                    setToken(res.token);
                    setLoginState("NEW_PROFILE");
                } else if (res && res.resp_type === "TOKEN") {
                    setAcct(res.acct);
                    setToken(res.token)
                    setSystemNotice(true)
                    //localStorage.setItem('user', res.token ? res.token : "")
                    //window.location.href = "/"
                }
            }).catch(()=>{});
        }
    });

    const AcceptSystemNotice = () =>
    {
        setSystemNotice(false)
        localStorage.setItem('user', token ? token : "")
        window.location.href = "/"
    }

    // @ts-ignore
    return (
        <div className="min-w-screen min-h-screen bg-gray-800">
            <Dialog
                open={systemNotice}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <SystemNotice AcceptSystemNotice = {AcceptSystemNotice}/>
            </Dialog>
            
            {loginState === "INIT" && <></>}
            {loginState === "NEW_PROFILE" && <OIDCNewUser acct={acct} token={token} />}
        </div>
    );
}

export default SAML_LOGIN;