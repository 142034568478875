import MapIcon from "@mui/icons-material/Map";
import { Alert, Button, ButtonGroup, Snackbar } from "@mui/material";
import { getValue } from "@syncfusion/ej2-base";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import {
    Column, ColumnDirective, ColumnMenu, ColumnsDirective,
    ExcelExport, ExcelExportProperties,
    Filter, FilterSettingsModel,
    Grid, GridComponent,
    Inject, Page, PagerComponent, PdfExport, Reorder, Resize, Search, Sort, Toolbar
} from "@syncfusion/ej2-react-grids";
import React from "react";
import Marquee from "react-fast-marquee";
import NJSnapIcon from "../../../assets/Images/NJSnapIcon.png";
import { GetStringNameForSite, SITE } from "../../../interfaces/config.interface";
import { JAOS } from "../../../services/JAOS.service";
import { SiteName } from "../../../services/config.service";
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import BTVehGridImage from "./_VehImg";

interface LPRTabGridProps {
    VINInfo: any
    TabGroup: any
    PagingInfo: any
    NextPage: any
    Errors: any
}

const LPRTabGridResults: React.FC<LPRTabGridProps> = ({ VINInfo, TabGroup, PagingInfo, NextPage, Errors }) => {
    let grid: Grid | null;
    let pagerInstance: PagerComponent | null;
    const [tData, setTData] = React.useState<any[]>([]);
    const [rowData, setRowData] = React.useState<any[]>([]);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<String>('')
    const [alertType, setAlertType] = React.useState<any>("success")
    const [errorList, setErrorList] = React.useState<string>("");
    //const [recordCount, setRecordCount] = React.useState<any>("");
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [paginationEnd, setPaginationEnd] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])

    const handlePaging = (event: any, tabGroup: any) => {
        if (event.requestType === 'paging') {
            let currentPage = event.currentPage
            let pageSize = grid?.pageSettings.pageSize ?? 1
            let totalRecordCount = grid?.pageSettings.totalRecordsCount ?? 1
            let lastPage = totalRecordCount / pageSize
            //
            handleTopPaging()
            //
            if (currentPage === lastPage) {
                NextPage(currentPage, tabGroup).then((pInfo: any) => { })
            }            
        }
    }

    const handleTopPaging = () => {
        if (pagerInstance && grid) {
            // assign grid pager values to the top pager values.
            pagerInstance.totalRecordsCount = grid.pagerModule.pagerObj.totalRecordsCount;
            pagerInstance.pageCount = grid.pageSettings.pageCount as number;
            pagerInstance.pageSizes = grid.pageSettings.pageSizes as [];
            pagerInstance.pageSize = grid.pageSettings.pageSize as number;
            pagerInstance.currentPage = grid.pageSettings.currentPage as number;
            //pagerInstance.refresh();
        }
    }

    const refreshGrid = () => {
        if (grid) {
            grid.refresh();
        }
    };

    React.useEffect(() => {
        let searchTerm = TabGroup;
        if (searchTerm !== 'ALL') {
            let tempP = VINInfo[searchTerm] ?? []
            if (tempP) {
                for (let p of tempP) {
                    if (p.LPRTimeStamp) {
                        p.LPRTimeStamp = new Date(p.LPRTimeStamp)
                    }
                }
            }   
            //
            if (grid) {
                grid.dataSource = tempP
                grid.pageSettings.currentPage = PagingInfo[searchTerm]?.CurrentPage ?? 1
                setRowData(tempP)
            }
            else {
                setTData(tempP);
                setRowData(tempP)
            }
        }
        else {
            //ALL tab data
            let tempP: any = Object.values(VINInfo).flat().sort()
            if (tempP) {
                for (let p of tempP) {
                    if (p.LPRTimeStamp) {
                        p.LPRTimeStamp = new Date(p.LPRTimeStamp)
                    }
                }
            }            
            //
            if (grid) {
                grid.dataSource = tempP
                grid.pageSettings.currentPage = PagingInfo[searchTerm]?.CurrentPage ?? 1
                setRowData(tempP)
            }
            else {
                setTData(tempP);
            }
        }
        refreshGrid()
    }, [VINInfo])

    const openGeoCode = (lprData: any[]) => {
        let pins: any[] = []
        if (grid) {
            for (let p of lprData) {
                if (p.LPRLocation && p?.LPRLocation?.length > 0) {
                    let LatLong = p.LPRLocation.split(',')
                    if (LatLong.length > 0) {
                        if (p.POIReason?.toLowerCase() == 'stolen vehicle' || p.POIReason?.toLowerCase() == 'stolen plate' ||
                            p?.POIReason?.toLowerCase() == 'felony stolen vehicle')
                            pins.push({
                                lat: LatLong[0],
                                lng: LatLong[1],
                                plate: p.PlateNum,
                                date: p.LPRTimeStamp,
                                alprSource: p.LPRCameraName, tool: "stolenCar"
                            })
                        else
                            pins.push({
                                lat: LatLong[0],
                                lng: LatLong[1],
                                plate: p.PlateNum,
                                date: p.LPRTimeStamp,
                                alprSource: p.LPRCameraName,
                                tool: "car"
                            })
                    }
                }
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            (grid.getColumnByField("LPRID") as Column).visible = false;
            (grid.getColumnByField("image") as Column).visible = false;

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'ALPRVin.xlsx'
            grid.excelExport(excelProp);
        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("LPRID") as Column).visible = true;
            (grid.getColumnByField("image") as Column).visible = true;
        }
    }

    const dataBound = () => {
        //TODO?
        if (grid) {
            grid.autoFitColumns([])
            //const pager = document.getElementsByClassName('e-gridpager');
            //let topElement = document.getElementsByClassName('e-gridheader');
            //grid.element.insertBefore(pager[0], topElement[0]);
        }
        if (pagerInstance && grid) {
            // assign grid pager values to the top pager values.
            pagerInstance.totalRecordsCount = grid.pagerModule.pagerObj.totalRecordsCount;
            pagerInstance.pageCount = grid.pageSettings.pageCount as number;
            pagerInstance.pageSizes = grid.pageSettings.pageSizes as [];
            pagerInstance.pageSize = grid.pageSettings.pageSize as number;
            pagerInstance.currentPage = grid.pageSettings.currentPage as number;
            //pagerInstance.refresh();
        }
    }
    const rowDataBound = (data: any) => {
        if (data.row) {
            if (getValue('POIReason', data.data) && (getValue('POIReason', data.data).length > 0)) {
                data.row.classList.add('redText');
            }
        }
    }

    const miniVehImage = (lprRecord: any): any => {
        return (
            <BTVehGridImage lprRecord={lprRecord} origination='STAGING' />
        )
    }

    const createReport = (lprData: any[]) => {
        //check that we got a result from the search for the same plate number
        //create string of ids returned
        let maxRecords = 500

        let flag = false
        let temp = lprData.map(x => x.LPRID)
        if (temp.length > maxRecords) {
            temp = temp.slice(0, maxRecords);
            flag = true
        }

        let idListStr = temp.join(',')
        if (idListStr.trim().length > 0) {
            let l = new JAOS()
            let hashID = l.objToStack(idListStr);
            let apiCall = "/detail/ALPRPDFReport" + '?ID=' + hashID + '&Dept=NONE;STAGING'
            if (flag === true)
                apiCall += '&maxRecords=' + maxRecords
            window.open(apiCall, '_blank')
        }
    }

    const pageOptions = {
        pageSize: 25,
        pageSizes: [10, 25, 50, 100],
        //pagerLocation: 'Both'
    };

    const handlePagerClick = (args: any) => {
        // change the page based on the top pager click.
        if (grid != null && pagerInstance != null) {
            grid?.goToPage(args.currentPage);
            //console.log(args.currentPage)
            pagerInstance.currentPage = args.currentPage as number;
        }
    };

    const handlePagerSizes = (args: any) => {
        //change the page size in the grids pager based on the value in the top pager.
        if (grid != null && pagerInstance != null) {
            grid.pageSettings.pageSize = args.pageSize;
            pagerInstance.pageSize = grid.pageSettings.pageSize as number;
        }
    }

    const viewTemplate = (item: any): any => {
        return (
            <span>
                {(item?.LPRID && item?.LPRID != 0 ? <Button onClick={() => {
                    window.open((item?.LPRServerName == "ETicketCentral" ?
                        "/detail/ETicket" : item?.LPRServerName == "ParkMobileCentral" ? "/detail/ParkMobile" : "/detail/ALPR") + '?ID=' + item?.LPRID + '&Dept='
                        + (item?.LPRServerName == "ETicketCentral" ? item?.Trim_Level : item?.LPRCityName + ';STAGING'), '_blank')
                }} >View</Button> : <div></div>)}
            </span>
        );
    }

    const filterOptions: FilterSettingsModel = {
        type: 'CheckBox'
        //,filterChoiceCount: '10000'
        //filterChoiceCount default is 1000. Not firing for higher values
        //https://www.syncfusion.com/forums/174976/column-menu-filtering-item-is-not-listing-all-the-options-on-the-data-grid-table
    };

    return (<div>
        <div className={"font-bold"}>
            <ul className="inline list-style-type:none;">Tab labels/colors:&nbsp;&nbsp;
                <li id="rectangle1" className={"rectangle-default inline"}>&nbsp;&nbsp;No records&nbsp;&nbsp;</li>
                <li id="rectangle2" className={"rectangle-processing inline"}>&nbsp;&nbsp;In process&nbsp;&nbsp;</li>
                <li id="rectangle3" className={"rectangle-done inline"}>&nbsp;&nbsp;No more data&nbsp;&nbsp;</li>
                <li id="rectangle4" className={"rectangle-issue inline"}>&nbsp;&nbsp;Connection issue&nbsp;&nbsp;</li>
                &nbsp;&nbsp;Please click last page to fetch more data&nbsp;&nbsp;
            </ul>
        </div>
        <div className={"pl-10 pr-10 mt-5"}>
            <Snackbar open={alertOpen} autoHideDuration={7000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            {Errors.length > 0 && errorList.length > 0 ?
                <Marquee speed={20} pauseOnHover={true}>
                    <span className="font-bold text-red-700">{errorList?.length > 0 ? errorList : ""}</span>
                </Marquee>
                : <></>}
            <span style={{ display: "inline-block" }} className='mb-5'>
                <div className={"font-bold inline mr-3"}>
                    {rowData.length > 0 ? rowData.length + (!paginationEnd && ((rowData.length % 1000) === 0) ? "+" : "") : 'NO'} Records Found
                </div>

                {rowData.length > 0 ?
                    <span>
                        <ButtonGroup size="medium" color="inherit" >
                            <Button variant="outlined" onClick={() => openGeoCode(rowData)}>
                                <MapIcon color="success" className="mr-2" /><b>Map</b>
                            </Button>
                            <Button variant="outlined" onClick={() => createReport(rowData)}>
                                {(SiteName === SITE.NJPROD || SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT) && <img src={NJSnapIcon} className={"mr-2"} alt='' />}
                                <b>Create Report</b>
                            </Button>
                        </ButtonGroup>
                        {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={'VINcar'}
                            exportData={rowData} /></div> : <></>}
                    </span>
                    : <></>}
            </span>
            {/* <PagerComponent
                ref={(pager) => (pagerInstance = pager)}
                click={handlePagerClick.bind(this)}
                dropDownChanged={handlePagerSizes.bind(this)}
            />*/}
            {tData ?
                <GridComponent
                    dataSource={tData}
                    dataBound={dataBound}
                    dataSourceChanged={refreshGrid}
                    rowDataBound={rowDataBound}
                    allowPaging={true}
                    pageSettings={pageOptions}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={filterOptions}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    toolbarClick={GridToolsClick}
                    ref={(g) => { if (g) { grid = g } }}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={27}
                    excelExportComplete={excelExportComplete}
                    style={{ color: 'red' }}
                    actionBegin={(e: any) => { handlePaging(e, TabGroup) }}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='LPRID' template={viewTemplate} headerText='View' />
                        <ColumnDirective field='image' template={miniVehImage} minWidth={120} maxWidth={240} headerText='Image' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LPRTimeStamp' headerText='Time Stamp' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }} width={140} minWidth={140}
                            maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LPRCameraName' headerText='Zone/ Camera' width={150} minWidth={150} maxWidth={150} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='PlateNum' headerText='Plate #' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='PlateState' headerText='Plate State' width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LPRCityName' headerText={`${GetStringNameForSite(SiteName, 'CITY')}`} width={150} minWidth={150} maxWidth={150}
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LPRLocation' headerText='ALPR Location' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='VendorName' headerText='Vendor Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='PlateYear' headerText='Plate Year' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='VehMake' headerText='Dmv Make' width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='VehModel' headerText='Dmv Model' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='VehBody' headerText='Dmv Trim' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='VehColor' headerText='Dmv Color' width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='VIN' headerText='Vin' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Model_Year' headerText='Vin Year' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Make' headerText='Vin Make' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Model' headerText='Vin Model' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Trim_Level' headerText='Trim/ID' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Body_Style' headerText='Body_Style' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Exterior_Color' headerText='Vin Color' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='POIReason' headerText='POI Purpose' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>
                : <></>}
        </div>
    </div>);
}

export default LPRTabGridResults