import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper, CardHeader } from "@mui/material";
import ETraceDetail, { ETraceATFAgentObject, ETraceDealerObject, ETraceIncidentObject, ETracePersonObject, ETraceProjectObject, ETraceReportingOfficerObject, ETraceVehicleObject, ETraceWeaponObject } from "../../interfaces/Detail/ETraceDetail.interface"
import { RunETraceDetailQuery } from "../../services/detail.service";
import { formatDT, formatDate } from "../../services/formatDate.service"

import './Detail.css'
import { GetETrace } from "../../services/account.service";
import DetailMap from "./_DetailMap";

interface DetailProps {
    ID: string | null
    department: string | null
}


const ETraceDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<ETraceDetail>({})
    const [pin, setPin] = React.useState<any>({})


    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            //let res = await RunETraceDetailQuery(ID, department);
            let res = await GetETrace({main:{ID:ID , FTS_ID:department}})
            setData(res)
            createPin(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data:any) => {
        let tPin:any = []
        if(Data && Data.Incidents.length > 0){
            for (let incident of Data.Incidents){
                if(incident.Latitude && incident.Longitude){
                    tPin.push({
                        incidentID: incident.ID,
                        lat: incident.Latitude,
                        lng: incident.Longitude,
                        address: incident.REC_STREET_NUMBER + " " + incident.REC_STREET_NAME + " " + incident.REC_STREET_SUFFIX,
                        city: incident.REC_CITY,
                        detail:"etrace"
                    })
                }                
            }
        }
        setPin(tPin)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Main Information</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Data.Main?.ID}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">FTS ID</span><span className ="DetailTableData">{Data.Main?.FTS_ID}</span></div>
                    {/* <div className ="DetailTableRow" ><span className = "DetailTableLabel">FTS ID Original</span><span className ="DetailTableData">{Data.Main?.FTS_IDOriginal}</span></div> */}
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Entry DateTime</span><span className ="DetailTableData">{formatDT(Data.Main?.REQUEST_DATE)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Agency Name</span><span className ="DetailTableData">{Data.Main?.OFFICE}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Agency ORI</span><span className ="DetailTableData">{Data.Main?.DEPT}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Case #</span><span className ="DetailTableData">{Data.Main?.CaseNumber}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Contact #</span><span className ="DetailTableData">{Data.Main?.CONTACTNo}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Remark</span><span className ="DetailTableData">{Data.Main?.REMARK}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Trace Type</span><span className ="DetailTableData">{Data.Main?.TraceType}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Created DateTime</span><span className ="DetailTableData">{formatDT(Data.Main?.CreateDateTime)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Status</span><span className ="DetailTableData">{Data.Main?.Status}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Priority</span><span className ="DetailTableData">{Data.Main?.Priority}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Crime Code</span><span className ="DetailTableData">{Data.Main?.Crime_Code}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Date</span><span className ="DetailTableData">{formatDate(Data.Main?.completion_date)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Code</span><span className ="DetailTableData">{Data.Main?.completion_code}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Status</span><span className ="DetailTableData">{Data.Main?.completion_status}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Description</span><span className ="DetailTableData">{Data.Main?.completion_description}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Remarks</span><span className ="DetailTableData">{Data.Main?.completion_remarks}</span></div>

                </CardContent>
            </Card>

            {Data.Weapons && Data.Weapons.length > 0 ? 
                Data.Weapons?.map((Weapon: ETraceWeaponObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-5 font-bold font text-xl text-blue-400">Weapon Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Weapon.ID}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Recovery DateTime</span><span className ="DetailTableData">{formatDT(Weapon.RECOVERY_DATE)}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Caliber</span><span className ="DetailTableData">{Weapon.CALIBER}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Make</span><span className ="DetailTableData">{Weapon.MANUFACTURER_NAME}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Code</span><span className ="DetailTableData">{Weapon.MANUFACTURER}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Model</span><span className ="DetailTableData">{Weapon.MODEL}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Serial #</span><span className ="DetailTableData">{Weapon.SERIAL_NUMBER}</span></div>                              
                                {/* <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Type</span><span className ="DetailTableData">{Weapon.W_TYPE}</span></div>                               */}
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Type Description</span><span className ="DetailTableData">{Weapon.W_TYPE_DESC}</span></div>                              
                                {/* <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Origin</span><span className ="DetailTableData">{Weapon.ORIGIN}</span></div>                               */}
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weapon Origin Country Name</span><span className ="DetailTableData">{Weapon.ORIGIN_COUNTRY_NAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Importer Name</span><span className ="DetailTableData">{Weapon.IMPORTER_NAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Identifying Marks</span><span className ="DetailTableData">{Weapon.IDENTIFYING_MARKS}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Obliterated Indicator</span><span className ="DetailTableData">{Weapon.DEFACED_GUN}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Code</span><span className ="DetailTableData">{Weapon.COMPLETION_CODE}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Text</span><span className ="DetailTableData">{Weapon.COMPLETION_TEXT}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Drill Size</span><span className ="DetailTableData">{Weapon.drill_size}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Barrel Size</span><span className ="DetailTableData">{Weapon.drill_size}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Finish</span><span className ="DetailTableData">{Weapon.finish}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">NIBIN</span><span className ="DetailTableData">{Weapon.NIBIN}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Privately Made Firearm</span><span className ="DetailTableData">{Weapon.privately_made_firearm}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Privately Made Firearm Category</span><span className ="DetailTableData">{Weapon.privately_made_firearm_category}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Privately Made Firearm Source</span><span className ="DetailTableData">{Weapon.privately_made_firearm_source}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Privately Made Firearm Material Composition</span><span className ="DetailTableData">{Weapon.privately_made_firearm_material_composition}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Privately Made Firearm Material Color</span><span className ="DetailTableData">{Weapon.privately_made_firearm_material_color}</span></div>                              
                            </CardContent>
                        </Card>
                    )
                })
                : 
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Weapon Information</h3>
                    </CardContent>
                </Card>
            }

            {Data.Dealers && Data.Dealers.length > 0 ? 
                Data.Dealers?.map((Dealer: ETraceDealerObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">FFL Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Dealer.ID}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Sequeance</span><span className ="DetailTableData">{Dealer.DEALER_SEQ}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">FFL #</span><span className ="DetailTableData">{Dealer.DEALER_FFL_NUMBER}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Business Name</span><span className ="DetailTableData">{Dealer.DEALER_BUSINESS_NAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">License Name</span><span className ="DetailTableData">{Dealer.DEALER_LICENSEE_NAME}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Address</span><span className ="DetailTableData">{Dealer.DEALER_STREET}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{Dealer.DEALER_CITY}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className ="DetailTableData">{Dealer.DEALER_STATE}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Zip</span><span className ="DetailTableData">{Dealer.DEALER_ZIP}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">County</span><span className ="DetailTableData">{Dealer.DEALER_COUNTY}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Country</span><span className ="DetailTableData">{Dealer.DEALER_COUNTRY}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Phone #</span><span className ="DetailTableData">{Dealer.DEALER_PHONE}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Out of Business</span><span className ="DetailTableData">{Dealer.DEALER_PHONE}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Ship Date</span><span className ="DetailTableData">{formatDate(Dealer.DEALER_SHIP_DATE)}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Final Sale</span><span className ="DetailTableData">{Dealer.Final_Sale}</span></div>                              
                            </CardContent>
                        </Card>
                    )
                })               
                : 
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">FFL Information</h3>
                    </CardContent>
                </Card>
            }

            {Data.Incidents && Data.Incidents.length > 0 ? 
                Data.Incidents?.map((Incident: ETraceIncidentObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-5 font-bold font text-xl text-blue-400">Incident Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Incident.ID}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Incident DateTime</span><span className ="DetailTableData">{formatDT(Incident.REC_DATE)}</span></div>                              
                                {/* <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street #</span><span className ="DetailTableData">{Incident.REC_STREET_NUMBER}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street Name</span><span className ="DetailTableData">{Incident.REC_STREET_NAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Type</span><span className ="DetailTableData">{Incident.REC_STREET_SUFFIX}</span></div>*/}
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Address</span><span className ="DetailTableData">{Incident.REC_STREET_NUMBER} {Incident.REC_STREET_NAME} {Incident.REC_STREET_SUFFIX}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Apt #</span><span className ="DetailTableData">{Incident.REC_APT_NUMBER}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{Incident.REC_CITY}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Incident County</span><span className ="DetailTableData">{Incident.REC_COUNTY}</span></div>                                                                                           
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className ="DetailTableData">{Incident.REC_STATE}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Zip</span><span className ="DetailTableData">{Incident.REC_ZIP}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Country</span><span className ="DetailTableData">{Incident.REC_COUNTRY}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Location Type</span><span className ="DetailTableData">{Incident.REC_Location_Type}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street Direction</span><span className ="DetailTableData">{Incident.REC_STREET_DIRECTION}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Route #</span><span className ="DetailTableData">{Incident.REC_ROUTE_NUMBER}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Longitude</span><span className ="DetailTableData">{Incident.Longitude}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Latitude</span><span className ="DetailTableData">{Incident.Latitude}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Time to Crime</span><span className ="DetailTableData">{Incident.REC_TIME_TO_CRIME}</span></div>                              
                                {pin && pin.length > 0 ? pin.filter((res : any) => res.incidentID === Incident.ID).map((Pin : any)=>{
                                    return(
                                        <div className="DetailTableRow border-2 border-black"> <DetailMap data={Pin} detail="etrace" /> </div> 
                                    )
                                }): <></>}

                            </CardContent>
                        </Card>
                    )
                })
                : 
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Incident Information</h3>
                    </CardContent>
                </Card>
            }

           {Data.Persons && Data.Persons.length > 0 ?
                Data.Persons?.map((Person: ETracePersonObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Person.ID}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Sequence</span><span className ="DetailTableData">{Person.INDIV_SEQ}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Role</span><span className ="DetailTableData">{Person.INDIV_ROLE}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Involved Date</span><span className ="DetailTableData">{formatDate(Person.INDIV_INVOLVE_DATE)}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">First Name</span><span className ="DetailTableData">{Person.INDIV_FIRSTNAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Middle Name</span><span className ="DetailTableData">{Person.INDIV_MIDDLENAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Last Name</span><span className ="DetailTableData">{Person.INDIV_LASTNAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Suffix</span><span className ="DetailTableData">{Person.INDIV_NAME_SUFFIX}</span></div>
                                <div className="DetailTableRow"  ><span className = "DetailTableLabel">DOB</span><span className="DetailTableData">{formatDate(Person.INDIV_DOB)}</span></div>
                                <div className="DetailTableRow"  ><span className = "DetailTableLabel">Sex</span><span className="DetailTableData">{Person.INDIV_SEX}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Race</span><span className ="DetailTableData">{Person.INDIV_RACE}</span></div>                            
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Height</span><span className ="DetailTableData">{Person.INDIV_HEIGHT}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Weight</span><span className ="DetailTableData">{Person.INDIV_WEIGHT}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POB</span><span className ="DetailTableData">{formatDate(Person.INDIV_POB)}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POB Address</span><span className ="DetailTableData">{Person.INDIV_POB_STREET}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POB City</span><span className ="DetailTableData">{Person.INDIV_POB_CITY}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POB County</span><span className ="DetailTableData">{Person.INDIV_POB_COUNTY}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POB State</span><span className ="DetailTableData">{Person.INDIV_POB_STATE}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POB Zip</span><span className ="DetailTableData">{Person.INDIV_POB_ZIP}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POB Country</span><span className ="DetailTableData">{Person.INDIV_POB_COUNTRY}</span></div>   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">FBI</span><span className ="DetailTableData">{Person.FBI}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">SBI</span><span className ="DetailTableData">{Person.SBINo}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">SBI State</span><span className ="DetailTableData">{Person.SBIState}</span></div>                              
                                {/* <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street #</span><span className ="DetailTableData">{Person.INDIV_STREET_NUMBER}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street Name</span><span className ="DetailTableData">{Person.INDIV_STREET_NAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Type</span><span className ="DetailTableData">{Person.INDIV_STREET_SUFFIX}</span></div>   */}
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Address</span><span className ="DetailTableData">{Person.INDIV_STREET_NUMBER} {Person.INDIV_STREET_NAME} {Person.INDIV_STREET_SUFFIX}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Apt #</span><span className ="DetailTableData">{Person.INDIV_APT_NUMBER}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Route #</span><span className ="DetailTableData">{Person.INDIV_ROUTE_NUMBER}</span></div>                                                            
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{Person.INDIV_CITY}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">County</span><span className ="DetailTableData">{Person.INDIV_COUNTY}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className ="DetailTableData">{Person.INDIV_STATE}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Zip</span><span className ="DetailTableData">{Person.INDIV_ZIP}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Country</span><span className ="DetailTableData">{Person.INDIV_COUNTRY}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street Direction</span><span className ="DetailTableData">{Person.INDIV_STREET_DIRECTION}</span></div> 
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Longitude</span><span className ="DetailTableData">{Person.INDIV_Address_Longitude}</span></div> 
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Latitude</span><span className ="DetailTableData">{Person.INDIV_Address_Latitude}</span></div> 
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Gang Name</span><span className ="DetailTableData">{Person.GANG_NAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Daily Crime Gun</span><span className ="DetailTableData">{Person.DailyCrimeGun}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Arrests</span><span className ="DetailTableData">{Person.Arrests}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Felony</span><span className ="DetailTableData">{Person.Felonys}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Prior Gun</span><span className ="DetailTableData">{Person.PriorGun}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">CRUMB</span><span className ="DetailTableData">{Person.CRUMB}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">POPHITS</span><span className ="DetailTableData">{Person.POPHITS}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Trigger Lock</span><span className ="DetailTableData">{Person.INDIV_TRIGGER_LOCK}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Crime History</span><span className ="DetailTableData">{Person.INDIV_CRIME_HISTORY}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Notes</span><span className ="DetailTableData">{Person.NOTES}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">AKA First Name</span><span className ="DetailTableData">{Person.INDIV_AKA_FIRSTNAME}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">AKA Middle Name</span><span className ="DetailTableData">{Person.INDIV_AKA_MIDDLENAME}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">AKA Last Name</span><span className ="DetailTableData">{Person.INDIV_AKA_LASTNAME}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">AKA Suffix</span><span className ="DetailTableData">{Person.INDIV_AKA_SUFFIX}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">AKA DOB</span><span className ="DetailTableData">{Person.INDIV_AKA_DOB}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID1 #</span><span className ="DetailTableData">{Person.INDIV_ID1_NUMBER}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID1 State</span><span className ="DetailTableData">{Person.INDIV_ID1_STAT}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID1 Type</span><span className ="DetailTableData">{Person.INDIV_ID1_TYPE}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID2 #</span><span className ="DetailTableData">{Person.INDIV_ID2_NUMBER}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID2 State</span><span className ="DetailTableData">{Person.INDIV_ID2_STATE}</span></div>                               
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID2 Type</span><span className ="DetailTableData">{Person.INDIV_ID2_TYPE}</span></div>                               
                            </CardContent>
                        </Card>
                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Information</h3>
                    </CardContent>
                </Card>
            }

            {Data.ReportingOfficers && Data.ReportingOfficers.length > 0 ?
                Data.ReportingOfficers?.map((ReportingOfficer: ETraceReportingOfficerObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Reporting Officer Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{ReportingOfficer.ID}</span></div>                              
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Badge #</span><span className="DetailTableData">{ReportingOfficer.ORI_BADGE_NUMBER}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">First Name</span><span className ="DetailTableData">{ReportingOfficer.ORI_FIRSTNAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Middle Name</span><span className ="DetailTableData">{ReportingOfficer.ORI_MIDDLENAME}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Last Name</span><span className ="DetailTableData">{ReportingOfficer.ORI_LASTNAME}</span></div>                                                                    
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ORI #</span><span className ="DetailTableData">{ReportingOfficer.ORI_NUMBER}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ORI Name</span><span className ="DetailTableData">{ReportingOfficer.ORI_NAME}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Address</span><span className ="DetailTableData">{ReportingOfficer.ORI_STREET}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{ReportingOfficer.ORI_CITY}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">County</span><span className ="DetailTableData">{ReportingOfficer.ORI_COUNTY}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className ="DetailTableData">{ReportingOfficer.ORI_STATE}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Zip</span><span className ="DetailTableData">{ReportingOfficer.ORI_STATE}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Country</span><span className ="DetailTableData">{ReportingOfficer.ORI_COUNTRY}</span></div>                                     
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Case #</span><span className ="DetailTableData">{ReportingOfficer.ORI_INV_NUMBER}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Completion Date</span><span className ="DetailTableData">{formatDate(ReportingOfficer.COMP_DATE)}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Entered User</span><span className ="DetailTableData">{ReportingOfficer.ENTERED_USER}</span></div>                                   
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Priority</span><span className ="DetailTableData">{ReportingOfficer.PRIORITY}</span></div>                                   

                            </CardContent>
                        </Card>
                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Reporting Officer Information</h3>
                    </CardContent>
                </Card>
            }

           {Data.Vehicles && Data.Vehicles.length > 0 ? 
                Data.Vehicles?.map((Vehicle:ETraceVehicleObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Vehicle.ID}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Vehicle Make</span><span className ="DetailTableData">{Vehicle.VehicleMake}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Vehicle Model</span><span className ="DetailTableData">{Vehicle.VehicleModel}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Year</span><span className ="DetailTableData">{Vehicle.VehicleYear}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Color</span><span className ="DetailTableData">{Vehicle.VehicleColor}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Plate Number</span><span className ="DetailTableData">{Vehicle.PlateNum}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Plate State</span><span className ="DetailTableData">{Vehicle.PlateSt}</span></div>                              

                            </CardContent>
                        </Card>
                    )})
                    : 
                    <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                        <CardContent>
                            <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                        </CardContent>
                    </Card>
            }

           {Data.ATFAgents && Data.ATFAgents.length > 0 ?
                Data.ATFAgents?.map((ATFAgent: ETraceATFAgentObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">ATF Agent Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{ATFAgent.ID}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Agent Badge #</span><span className ="DetailTableData">{ATFAgent.agent_badge_number}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Agent First Name</span><span className ="DetailTableData">{ATFAgent.agent_first_name}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Agent Last Name</span><span className ="DetailTableData">{ATFAgent.agent_last_name}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Case #</span><span className ="DetailTableData">{ATFAgent.case_number}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">E-trace User ID</span><span className ="DetailTableData">{ATFAgent.etrace_user_id}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Field Office Name</span><span className ="DetailTableData">{ATFAgent.field_office_name}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Field Office Number</span><span className ="DetailTableData">{ATFAgent.field_office_number}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Office Address</span><span className ="DetailTableData">{ATFAgent.office_address_1}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{ATFAgent.office_city}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className ="DetailTableData">{ATFAgent.office_state}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Zip</span><span className ="DetailTableData">{ATFAgent.office_zip}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Country</span><span className ="DetailTableData">{ATFAgent.office_country}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Phone #</span><span className ="DetailTableData">{ATFAgent.phone_number}</span></div>                              
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Phone Service Provider</span><span className ="DetailTableData">{ATFAgent.phone_service_provider}</span></div>                              

                            </CardContent>
                        </Card>

                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                        <CardContent>
                            <h3 className="mb-4 font-bold font text-xl text-blue-400">ATF Agent Information</h3>
                        </CardContent>
                </Card>
            }                
           
            {Data.Projects && Data.Projects.length > 0 ? 
                Data.Projects?.map((Project: ETraceProjectObject)=>{
                    return(
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Project Information</h3>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Project.ID}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Trace Indicator Code</span><span className ="DetailTableData">{Project.trace_indicator_code}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Trace Indicator Description</span><span className ="DetailTableData">{Project.trace_indicator_description}</span></div>
                                <div className ="DetailTableRow" ><span className = "DetailTableLabel">Trace Indicator Sequence</span><span className ="DetailTableData">{Project.trace_indicator_sequence}</span></div>
                            </CardContent>
                        </Card>
                    )

                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                        <CardContent>
                            <h3 className="mb-4 font-bold font text-xl text-blue-400">Project Information</h3>
                        </CardContent>
                </Card>            
            }

        </div>
    );
}

export default ETraceDetailInfo;